import styles from './ContactsPage.module.scss'
import Header from "../../components/Header/Header";
import HeaderMobile from "../../components/HeaderMobile/HeaderMobile";
import inst from "../../images/inst.svg";
import telegram from "../../images/telegram.svg";
import whatsapp from "../../images/whatsapp.svg";

const ContactsPage = () => {
    return (
        <div>
            <Header />
            <HeaderMobile />
            <div className={styles.contactsWrapper}>
                <div>
                    Наш адрес: Республика Казахстан, Алматинская область, Талгарский район, поселок Тузусай, ул. Сырым Датулы 57.
                </div>
                <div>
                    +7 707 771 15 64 – Отдел продаж
                </div>
                <div>
                    +7 700 212 98 83 – Отдел продаж
                </div>
                <div>
                    +7 700 212 99 38 – Офис, Отдел кадров
                </div>
            </div>
            <div>
                <div className={styles.social}>
                    <div className={styles.socialItem}>
                        <a href="https://instagram.com/egpi.kz?igshid=Zjc2ZTc4Nzk" target="_blank">
                            <img src={inst} alt="instagram" />
                        </a>
                    </div>
                    <div className={styles.socialItem}>
                        <a href="https://t.me/EGPI_Almaty" target="_blank">
                            <img src={telegram} alt="instagram" />
                        </a>
                    </div>
                    <div>
                        <a href="https://wa.me/77077711564" target="_blank">
                            <img src={whatsapp} alt="whatsapp" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactsPage;